import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import VueSocketIO from 'vue-socket.io'
import SocketIO from "socket.io-client"

createApp(App).use(router).use(new VueSocketIO({
    debug: false,
    connection: SocketIO(localStorage.getItem('api2'), {
        auth: {
        }, query: { user: localStorage.getItem('user'), url: encodeURI(window.location) }
    })
})).mount('#app')
