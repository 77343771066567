<template>
  <div class="container-fluid">
    <div id="header" class="row">
      <div class="col-12 col-lg-4 c">
        <br />
        <a
          href="https://tahdir.net/user"
          style="margin-top: 6px; margin-right: 20px"
          class="btn btn-secondary"
        >
          <i class="fa fa-reply"></i>
          العودة للتحضير الذكي
        </a>
        <br />
        <router-link
          to="/logout"
          class="text-white btn btn-sm"
          style="margin-right: 20px"
          ><i class="fa fa-sign-out"></i> تسجيل الخروج من هنا</router-link
        >
      </div>
      <div class="col-12 col-lg-4 c text-center">
        <div class="d-flex p-3 text-center">
          <img
            v-if="user.avatar"
            :src="api + '/uploads/avatars/' + user.avatar"
            class="flex-shrink-0"
            style="
              width: 80px;
              height: 80px;
              object-fit: cover;
              border-radius: 10px;
            "
          />
          <div>
            <br />
            <h4 style="padding-right: 10px">{{ user.name }}</h4>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 c">
        <br />
        <button
          href="https://tahdir.net/user"
          style="margin-top: 10px; margin-right: 50px"
          class="btn btn-primary"
          @click="settings()"
        >
          <i class="fa fa-cogs"></i>
          إعدادات شاشة الفحص
        </button>
        <br />
        <span
          class="badge bg-danger"
          v-if="user.settings.send_late == '1' || user.settings.send_end == '1'"
          style="margin-right: 50px"
          ><i class="fa fa-paper-plane"></i> الارسال التلقائي مُفعل</span
        >
      </div>
    </div>
    <div class="row dnone g" id="settings">
      <div class="col-12 col-lg-7 c g">
        <div class="card">
          <div class="card-header">
            <h4>
              <i class="fa fa-cogs"></i>
              إعدادات شاشة الفحص
            </h4>
            <!-- </div>
          <div class="card-body">
            <div class="form-check">
              <label class="form-check-label" style="cursor: pointer">
                <h5>
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="manual"
                    value="true"
                    @click="settings()"
                  />
                  تفعيل الكتابة يدوياً
                </h5>
              </label>
            </div>-->
          </div>
          <div class="card-footer bg-white">
            <h5>
              <i class="fa fa-paper-plane"></i>
              الارسال التلقائي |
              <a
                href="https://tahdir.net/user/students/settings"
                target="_blank"
                class="btn btn-sm btn-primary"
              >
                <i class="fa fa-cog"></i>
                اضغط هنا لتغيير اعدادات الارسال
              </a>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="row g" v-if="manual" id="manual">
      <div class="col-12 col-lg-5 c g">
        <div class="card gg">
          <div class="card-header">
            <h4>
              <i class="fa fa-edit"></i>
              كتابة رقم الهوية يدوياً
            </h4>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="">رقم الهوية</label>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="اكتب هنا..."
                v-model="manualValue"
              />
            </div>
            <div class="col-12 text-center">
              <br />
              <button class="btn btn-success" @click="scanManual()">
                <i class="fa fa-search"></i>
                فحص الآن
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="body" class="row g">
      <div class="col-12 upload_error"></div>
      <div class="col-12 col-lg-3 g c">
        <div class="card card-body" style="border-top: solid 4px #019267">
          <h2>{{ status_1 }}</h2>
          <span>
            <i class="fa fa-check text-muted"></i>
            عدد الحاضرين
          </span>
        </div>
        <br />
        <div class="card card-body" style="border-top: solid 4px #ffd365">
          <h2>{{ status_2 }}</h2>
          <span>
            <i class="fa fa-clock-o text-muted"></i>
            عدد المتأخرين
          </span>
        </div>
      </div>
      <div class="col-12 col-lg-6 g c">
        <div class="card" style="height: 100%">
          <div :class="'card-body text-center'">
            <br />
            <h1><i :class="'fa fa-' + icon"></i></h1>
            <h2 :class="'text-' + bg">{{ title }}</h2>
            <br />
            <p>
              {{ text }}
            </p>
            <input
              type="text"
              id="last-barcode"
              style="border: none; background: #eee"
              ref="input"
              autofocus
              @keypress="keypress()"
            />
            <br />
            <br />
            <button class="btn btn-primary btn-sm start_btn" @click="start()">
              <i class="fa fa-search"></i>
              بدء الفحص
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 g c">
        <div class="card card-body" style="border-top: solid 4px #fd5d5d">
          <h2>{{ status_4 }}</h2>
          <span>
            <i class="fa fa-sign-out text-muted"></i>
            عدد الإنصرافات</span
          >
        </div>
        <br />
        <div class="card card-body" style="border-top: solid 4px #2d31fa">
          <h2>{{ students.length }}</h2>
          <span><i class="fa fa-users text-muted"></i> عدد الطلاب الكلي</span>
        </div>
      </div>
    </div>
    <div class="row g">
      <div class="col-12 col-lg-6 g c">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-8 g">
                <h4>السجلات المحفوظة (عمليات الفحص)</h4>
              </div>
              <div class="col-4 g">
                <button
                  class="btn btn-success"
                  id="uploadReports"
                  @click="uploadReports()"
                >
                  <i class="fa fa-upload"></i>
                  رفع التقرير الآن
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="col-12 table-responsive">
              <table class="table table-hover table-striped">
                <thead>
                  <th>الطالب</th>
                  <th>الحالة</th>
                </thead>
                <tbody id="reports-log"></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 g c">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-12 g">
                <h4>سجل ارسال التأخر والانصراف التلقائي</h4>
                <p class="text-muted">(يتم تفريغ السجل عند اغلاق الصفحة)</p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="col-12 table-responsive">
              <table class="table table-hover table-striped">
                <thead>
                  <tr>
                    <th>الطالب</th>
                    <th>الجوال</th>
                    <th>النوع</th>
                    <th>حالة الإرسال</th>
                    <th>تفاصيل</th>
                  </tr>
                </thead>
                <tbody class="attendLog"></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
global.jQuery = require("jquery");
var $ = global.jQuery;
const axios = require("axios").default;

export default {
  name: "Home",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      admin: JSON.parse(localStorage.getItem("admin")),
      api: localStorage.getItem("api"),
      manual: false,
      bg: "dark",
      manualValue: null,
      students: [],
      icon: "barcode",
      title: "يمكنك الفحص بالباركود الآن..",
      text: "او يمكنك الفحص يدوياً من خلال 'إعدادات شاشة الفحص' بالاعلى.",
      status_1: 0,
      status_2: 0,
      status_4: 0,
    };
  },
  created() {
    var user = localStorage.getItem("user"),
      admin = localStorage.getItem("admin"),
      g = this;
    if (user) {
      var apikey = JSON.parse(user)._id;
      g.$socket.emit("scan", {
        user: apikey,
        status: 9,
      });
    }
    if (!user) {
      this.$router.push("/noauth");
    } else {
      if (admin != null && admin != undefined) {
        if (!JSON.parse(admin).pers.includes("students_pre_barcode")) {
          this.$router.push("/noauth?2");
        }
      }
    }
    if (!window.location.href.includes("norefresh")) {
      if (admin != null && admin != undefined) {
        this.$router.push("/data/2/" + JSON.parse(admin).link);
      } else {
        this.$router.push("/data/1/" + JSON.parse(user).jwt);
      }
    } else {
      this.$router.push("/");
      axios
        .post(localStorage.getItem("api") + "/scan/students", {
          jwt: JSON.parse(user).jwt,
        })
        .then(function (d) {
          d = d.data;
          if (d.status == 100) {
            g.students = d.response;
            var i = -100;
            $(".card").each(function () {
              var a = $(this);
              setTimeout(function () {
                a.fadeIn("slow");
              }, i);
              i = i + 100;
            });
          } else {
            alert("حدث مشكلة اثناء جلب الطلاب..");
            $(".card").fadeOut("slow");
          }
        })
        .catch(function (e) {
          alert("حدث مشكلة اثناء جلب الطلاب \n" + e);
          $(".card").fadeOut("slow");
        });
      setInterval(() => {
        axios
          .post(localStorage.getItem("api") + "/scan/students", {
            jwt: JSON.parse(user).jwt,
          })
          .then(function (d) {
            d = d.data;
            if (d.status == 100) {
              g.students = d.response;
            } else {
              //
            }
          })
          .catch(function (e) {});
      }, 216000);
    }
    if (user) {
      //
      setTimeout(() => {
        setInterval(() => {
          $("#last-barcode").click();
          $("#last-barcode").focus();
        }, 1000);
      }, 2000);

      this.sockets.subscribe("scanDone", function (e) {
        if (e.user == apikey) {
          g.status_1 = new Number(e.data[1]);
          g.status_2 = new Number(e.data[2]);
          g.status_4 = new Number(e.data[4]);
        }
      });
      ////
      setInterval(() => {
        $("#reports-log").html(``);
        var uploaded = localStorage.getItem("reports-uploaded");
        if (uploaded == null || uploaded == undefined) {
          uploaded = "[]";
        }
        uploaded = JSON.parse(uploaded);
        var arr = localStorage.getItem("reports");
        if (arr == null || arr == undefined) {
          arr = "[]";
        }
        arr = JSON.parse(arr);
        $.each(arr, function (i, ss) {
          var v = null;
          uploaded.forEach(function (a) {
            if (a.number == ss.student.number && a.time == ss.time) {
              v = a;
            }
          });
          $("#reports-log").prepend(
            `<tr>
            <td><input class='ar' type='hidden' value='${JSON.stringify(
              ss
            )}'>` +
              ss.student.name +
              `</td>
            <td>` +
              (v
                ? `<span class='btn btn-success'><i class='fa fa-check'></i> ${v.status}</span>`
                : `<span class='btn btn-outline-secondary'><i class='fa fa-clock-o'></i> ${ss.sstatustext}</span>`) +
              `</td>
          </tr>`
          );
        });
      }, 1000);
      setInterval(() => {
        g.uploadReports();
      }, 300000);
      g.checkSingleUpload();
    }
  },
  methods: {
    checkSingleUpload() {
      var g = this;
      if ($("#reports-log tr .btn-outline-secondary").length) {
        var tr = $("#reports-log tr .btn-outline-secondary")
          .last()
          .parent()
          .parent();
        var ar = tr.find(".ar").val();
        $.post(localStorage.getItem("api") + "/scan/upload-report-single", {
          data: ar,
          jwt: g.user.jwt,
        })
          .then(function (e) {
            ar = JSON.parse(ar);
            if (e.number == ar.student.number) {
              var uploaded2 = localStorage.getItem("reports-uploaded");
              if (uploaded2 == null || uploaded2 == undefined) {
                uploaded2 = "[]";
              }
              uploaded2 = JSON.parse(uploaded2);
              uploaded2.push({
                number: e.number,
                status: e.status,
                time: ar.time,
              });
              localStorage.setItem(
                "reports-uploaded",
                JSON.stringify(uploaded2)
              );
              setTimeout(() => {
                g.checkSingleUpload();
              }, 500);
            } else {
              setTimeout(() => {
                g.checkSingleUpload();
                console.log(345234, "no");
              }, 1000);
            }
          })
          .fail(function (xhr, textStatus, errorThrown) {
            setTimeout(() => {
              g.checkSingleUpload();
            }, 1000);
            console.log(234234, xhr);
          });
      } else {
        setTimeout(() => {
          g.checkSingleUpload();
        }, 1000);
      }
    },
    settings() {
      $("#settings").toggle("slow");
    },
    handleBarcode(number) {
      var user = JSON.parse(localStorage.getItem("user")),
        admin = JSON.parse(localStorage.getItem("admin")),
        g = this;

      var apikey = user._id;
      // document.querySelector("#last-barcode").innerHTML = number;
      /* Start Scan */
      var student = this.studentByNumber(number);
      if (!student) {
        g.bg = "danger";
        g.title = "هذا الطالب غير موجود";
        g.text = "" + number;
        g.icon = "times";
      } else {
        if (student.settings_group) {
          if (student.settings_group.code) {
            var t = false;
            user.students_settings_groups.forEach(function (a) {
              if (a.code == student.settings_group.code) {
                t = true;
              }
            });
            if (!t) {
              g.bg = "warning";
              g.title = "مجموعة الاعدادات المعينة للطالب غير موجودة";
              g.text = "";
              g.icon = "cogs";
            } else {
              // settings group checked
              var settings = student.settings_group;
              if (user.settings.statuses.includes(student.health_status)) {
                // status checked
                var weekday = new Array(7);
                weekday[0] = "Sunday";
                weekday[1] = "Monday";
                weekday[2] = "Tuesday";
                weekday[3] = "Wednesday";
                weekday[4] = "Thursday";
                weekday[5] = "Friday";
                weekday[6] = "Saturday";
                if (
                  settings.days
                    .split(",")
                    .includes(weekday[new Date().getDay()])
                ) {
                  // day checked
                  var arr = localStorage.getItem("reports");

                  if (arr == null || arr == undefined) {
                    arr = "[]";
                  }

                  arr = JSON.parse(arr);
                  var ada = new Date().toISOString().split("T")[0] + " ";
                  var count = 0;

                  var current_time = Date.parse(
                    ada +
                      new Date()
                        .toLocaleTimeString("en-US", {
                          timeZone: "Asia/Riyadh",
                          hour12: false,
                        })
                        .split(":")[0] +
                      ":" +
                      new Date()
                        .toLocaleTimeString("en-US", {
                          timeZone: "Asia/Riyadh",
                          hour12: false,
                        })
                        .split(":")[1]
                  );

                  var sstatus = "0";
                  var sstatustext = "خارج وقت الدوام";

                  if (current_time >= Date.parse(ada + settings.end_date)) {
                    sstatus = 4;
                    sstatustext = "انصراف";
                  } else if (
                    current_time >= Date.parse(ada + settings.ghiab_date)
                  ) {
                    sstatus = 3;
                    sstatustext = "غياب";
                  } else if (
                    current_time >= Date.parse(ada + settings.late_date)
                  ) {
                    sstatus = 2;
                    sstatustext = "تأخر";
                  } else if (
                    current_time >= Date.parse(ada + settings.start_date)
                  ) {
                    sstatus = 1;
                    sstatustext = "حاضر";
                  }
                  $.each(arr, function (i, s) {
                    if (s.student.number == student.number) {
                      count = 1;
                    }
                  });
                  //////////////////////////
                  if (count == 0) {
                    g.$socket.emit("scan", {
                      user: apikey,
                      status: sstatus,
                      number: student.number,
                    });

                    arr.push({
                      student: {
                        name: student.name,
                        number: student.number,
                      },
                      time: new Date().toLocaleString("en-US", {
                        timeZone: "Asia/Riyadh",
                        hour12: false,
                      }),
                      status: sstatus,
                      sstatustext: sstatustext,
                      c: null,
                      settings: settings,
                    });

                    localStorage.setItem("reports", JSON.stringify(arr));

                    var alra = localStorage.getItem(
                      "reports-" + new Date().toJSON().slice(0, 10)
                    );

                    if (alra == null || alra == undefined) {
                      alra = [];
                    } else {
                      alra = JSON.parse(alra);
                    }

                    $.each(arr, function (i, aqa) {
                      t = true;

                      $.each(alra, function (i1, sa) {
                        if (sa.student.number == aqa.student.number) {
                          t = false;
                        }
                      });

                      if (t) {
                        alra.push(aqa);
                      }
                    });

                    localStorage.setItem(
                      "reports-" + new Date().toJSON().slice(0, 10),
                      JSON.stringify(alra)
                    );

                    var ty,
                      tyy,
                      msg,
                      csrf = null;

                    if (
                      (sstatus == 2 && user.settings.send_late == "1") ||
                      (sstatus == 4 && user.settings.send_end == "1")
                    ) {
                      ty = null;

                      if (user.settings.send_late == "1" && sstatus == 2) {
                        ty = "late";
                        tyy = "تأخر";
                        msg = user.settings.late_message;
                      }

                      if (user.settings.send_end == "1" && sstatus == 4) {
                        ty = "end";
                        tyy = "انصراف";
                        msg = user.settings.end_message;
                      }

                      axios
                        .post(localStorage.getItem("api") + "/scan/send", {
                          jwt: user.jwt,
                          name: student.name,
                          number: student.number,
                          type: ty,
                          message: msg,
                          time: new Date().toLocaleString("en-US", {
                            timeZone: "Asia/Riyadh",
                            hour12: false,
                          }),
                          phone: student.phone,
                        })
                        .then(function (e) {
                          if (e.data.status != 100) {
                            $(".attendLog").append(`
                                    <tr>
                                        <td>
                                            ${student.name}
                                        </td>
                                        <td>
                                            ${student.phone}
                                        </td>
                                        <td>
                                            ${tyy}
                                        </td>
                                        <td>
                                            <span class='badge bg-danger'>فشل الارسال</span>
                                        </td>
                                        <td>
                                            ${e.data.error}
                                        </td>
                                    </tr>
                                `);
                          } else {
                            $(".attendLog").append(`
                                    <tr>
                                        <td>
                                            ${student.name}
                                        </td>
                                        <td>
                                            ${student.phone}
                                        </td>
                                        <td>
                                            ${tyy}
                                        </td>
                                        <td>
                                            <span class='badge bg-success'>تم الارسال</span>
                                        </td>
                                        <td>
                                            تم الإرسال
                                        </td>
                                    </tr>
                                `);
                          }
                        })
                        .catch(function (e) {
                          $(".attendLog").append(`
                                    <tr>
                                        <td>
                                            ${student.name}
                                        </td>
                                        <td>
                                            ${student.phone}
                                        </td>
                                        <td>
                                            <span class='badge bg-danger'>فشل الارسال</span>
                                        </td>
                                        <td>
                                            خطأ في الشبكة لديك
                                            <br>
                                            ${e}
                                        </td>
                                    </tr>
                                `);
                        });
                    }
                    g.bg = "success";
                    g.title = "تم تسجيل الحالة: " + sstatustext;
                    g.text = "";
                    g.icon = "check";
                  } else {
                    g.bg = "warning";
                    g.title = "تم تسجيل الحالة من قبل";
                    g.text = "";
                    g.icon = "info";
                  }
                  //////////////////////////
                } else {
                  g.bg = "primary";
                  g.title = "يوم خارج الدوام";
                  g.text = "هذا الطالب ليس مسموح له بالدخول في هذا اليوم";
                  g.icon = "times";
                }
              } else {
                g.bg = "warning";
                g.title = "حالة الطالب الصحية: " + student.health_status;
                g.text = "غير مسموح بالدخول";
                g.icon = "times";
              }
            }
          } else {
            g.bg = "warning";
            g.title = "هذا الطالب ليس لديه مجموعة اعدادات";
            g.text = "";
            g.icon = "cogs";
          }
        } else {
          g.bg = "warning";
          g.title = "هذا الطالب ليس لديه مجموعة اعدادات";
          g.text = "";
          g.icon = "cogs";
        }
      }
      /* End Scan */
    },
    scanManual() {
      this.handleBarcode(this.manualValue);
      var g = this;
      setTimeout(() => {
        g.manualValue = null;
      }, 1000);
    },
    studentByNumber(number) {
      var r = false;
      this.students.forEach(function (s) {
        if (s.number == number) {
          r = s;
        }
      });
      return r;
    },
    uploadReports() {
      var arr = localStorage.getItem("reports");
      if (arr == null || arr == undefined) {
        arr = "[]";
      }
      var html = $("#uploadReports").html();
      $("#uploadReports").html("جاري الرفع...");
      $(".upload_error").html("");
      axios
        .post(localStorage.getItem("api") + "/scan/upload", {
          jwt: this.user.jwt,
          data: arr,
        })
        .then(function () {
          $("#uploadReports").html(html);
          if ($("#reports-log tr .btn-outline-secondary").length == 0) {
            localStorage.removeItem("reports");
            localStorage.removeItem("reports-uploaded");
          }
        })
        .catch(function (g) {
          $(".upload_error").html(
            "<div class='alert alert-danger text-center'>حدث مشكلة في رفع البيانات، برجاء التواصل مع الدعم الفني.</div>"
          );
          var errorr = btoa(g);
          $("#uploadReports").html(html);
          $(".upload_error").html(
            "<div class='alert alert-danger text-center'>حدث مشكلة في رفع البيانات، برجاء التواصل مع الدعم الفني. <br><a href='data:application/octet-stream;charset=utf-16le;base64," +
              errorr +
              "' download='error.txt'>إضغط هنا لتحميل الخطأ</a></div>"
          );
        });
    },
    onLoaded() {
      alert(1);
    },
    start() {
      $("#last-barcode").click();
      $("#last-barcode").focus();
      $(".start_btn").fadeOut("slow");
    },
    keypress() {
      var g = this;
      if (
        $("#last-barcode").val() != "" &&
        $("#last-barcode").val().length > 8
      ) {
        setTimeout(function () {
          g.handleBarcode($("#last-barcode").val());
          $("#last-barcode").val("");
        }, 50);
      }
    },
  },
};
</script>
