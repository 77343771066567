<template>
  <div>
    <div class="row">
      <div class="col-12 text-center">
        <br /><br />
        <h3 class="alert alert-info">تم تسجيل الخروج بنجاح!</h3>
        <br /><br />
        <a href="https://tahdir.net/user" class="btn btn-success">
          <i class="fa fa-reply"></i>
          العودة للتحضير الذكي
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    localStorage.removeItem("user");
    localStorage.removeItem("admin");
  },
};
</script>
