import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Join from '../views/Join.vue'
import noauth from '../views/noauth.vue'
import logout from '../views/logout.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/data/:type/:jwt',
    name: 'join',
    component: Join
  },
  {
    path: '/noauth',
    name: 'noauth',
    component: noauth
  },
  {
    path: '/logout',
    name: 'logout',
    component: logout
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
