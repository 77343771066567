<template>
  <div>
    <div class="row">
      <div class="col-12 text-center">
        <br /><br />
        <h3 class="text-dark">
          لا يوجد جلسة موجودة، برجاء الدخول عن طريق موقع التحضير الذكي.
        </h3>
        <h5>او ربما ليس لديك صلاحيات للوصول لهذه الصفحة.</h5>
        <br /><br />
        <a href="https://tahdir.net/user" class="btn btn-success">
          <i class="fa fa-reply"></i>
          العودة للتحضير الذكي
        </a>
      </div>
    </div>
  </div>
</template>

