<template>
  <div>
    <div class="row">
      <div class="col-12 text-center">
        <br /><br />
        <h3>
          <img
            src="@/assets/loading.svg"
            style="width: 50px; height: 50px"
            alt=""
          />
          جاري الدخول..
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "join",
  components: {},
  created() {
    var type = window.location.href.split("/data/")[1].split("/")[0],
      data = window.location.href.split("/data/")[1].split("/")[1],
      g = this;
    axios
      .post(localStorage.getItem("api") + "/scan/login", {
        type: type,
        data: data,
      })
      .then(function (d) {
        d = d.data;
        if (d.status == 100) {
          if (type == "1") {
            localStorage.setItem("user", JSON.stringify(d.response));
          } else {
            localStorage.setItem("user", JSON.stringify(d.response.user));
            localStorage.setItem("admin", JSON.stringify(d.response.admin));
          }
          g.$router.push("/?norefresh");
        } else {
          alert("حدث خطأ ما، برجاء التواصل مع الدعم الفني لحل المشكلة..");
        }
      })
      .catch(function (e) {
        alert("حدث خطأ ما، برجاء التواصل مع الدعم الفني لحل المشكلة. \n" + e);
      });
  },
};
</script>
